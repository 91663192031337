import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { Spinner } from "react-bootstrap";
export default function TimerComponent() {
  const [times, setTimes] = useState({
    num_days: -1,
    months: -1,
    days: -1,
    percent: -1,
  });
  useEffect(() => {
    calcTimes();
  }, []);

  const calcTimes = () => {
    const d2 = new Date(2023, 10, 10);
    const d1 = new Date();
    const timeDifferenceMs = d2 - d1;
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const millisecondsPerHour = 1000 * 60 * 60;
    const diff = Math.floor(timeDifferenceMs / millisecondsPerDay);
    const hours = Math.floor(
      timeDifferenceMs / millisecondsPerHour - diff * 24
    );

    const months = Math.floor(diff / 30);
    const days = diff - months * 30;
    const num_days = diff;
    const percent = Math.ceil(((30 - num_days) / 30) * 100);
    setTimes({ num_days, months, days, percent, hours });
  };
  console.log(times);
  const { num_days, days, percent, hours } = times; //months
  if (num_days === -1)
    return (
      <Container>
        <Row>
          <Col>
            <h1>IELTS</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  return (
    <Container>
      <Row>
        <Col>
          <h1>IELTS</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressBar variant="warning" animated now={percent} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>
            {`Number of days remaining: `}
            <Badge pill bg="warning" text="dark">
              {num_days}
            </Badge>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>
            {/* <Badge pill bg="warning" text="dark">
              {months}
            </Badge>
            {` month${months > 1 ? "s" : ""} `} */}
            <Badge pill bg="warning" text="dark">
              {days}
            </Badge>
            {` day${days > 1 ? "s" : ""} `}

            <Badge pill bg="warning" text="dark">
              {hours}
            </Badge>
            {` hour${hours > 1 ? "s" : ""} `}
          </h2>
        </Col>
      </Row>
    </Container>
  );
}
